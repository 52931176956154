/*general*/
html{
  font-size: 16px;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}
body{
  position: relative;


  color: var(--white);

  background: var(--blue);
}

body,
html{
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  font-family: var(--primaryFont);
}

#root{
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  --colorHue: var(--purpleHue);
  --color: var(--purple);
  --color1: hsl(var(--colorHue), 100%, 50%);
  --color2: hsl(var(--colorHue), 80%, 50%);
  --color3: hsl(var(--colorHue), 60%, 50%);
  --color4: hsl(var(--colorHue), 40%, 50%);
  --color5: hsl(var(--colorHue), 20%, 50%);
  --colorLight1: hsl(var(--colorHue), 100%, 70%);
  --colorLight2: hsl(var(--colorHue), 80%, 70%);
  --colorLight3: hsl(var(--colorHue), 60%, 70%);
  --colorLight4: hsl(var(--colorHue), 40%, 70%);
  --colorLight5: hsl(var(--colorHue), 20%, 70%);
  --colorLighter1: hsl(var(--colorHue), 100%, 90%);
  --colorLighter2: hsl(var(--colorHue), 80%, 90%);
  --colorLighter3: hsl(var(--colorHue), 60%, 90%);
  --colorLighter4: hsl(var(--colorHue), 40%, 90%);
  --colorLighter5: hsl(var(--colorHue), 20%, 90%);
  --colorDark1: hsl(var(--colorHue), 100%, 30%);
  --colorDark2: hsl(var(--colorHue), 80%, 30%);
  --colorDark3: hsl(var(--colorHue), 60%, 30%);
  --colorDark4: hsl(var(--colorHue), 40%, 30%);
  --colorDark5: hsl(var(--colorHue), 20%, 30%);
  --colorDarker1: hsl(var(--colorHue), 100%, 10%);
  --colorDarker2: hsl(var(--colorHue), 80%, 10%);
  --colorDarker3: hsl(var(--colorHue), 60%, 10%);
  --colorDarker4: hsl(var(--colorHue), 40%, 10%);
  --colorDarker5: hsl(var(--colorHue), 20%, 10%);

  --colorHsl: var(--purpleHsl);
  --colorHsl1: var(--colorHue), 100%, 50%;
  --colorHsl2: var(--colorHue), 80%, 50%;
  --colorHsl3: var(--colorHue), 60%, 50%;
  --colorHsl4: var(--colorHue), 40%, 50%;
  --colorHsl5: var(--colorHue), 20%, 50%;
  --colorHslLight1: var(--colorHue), 100%, 70%;
  --colorHslLight2: var(--colorHue), 80%, 70%;
  --colorHslLight3: var(--colorHue), 60%, 70%;
  --colorHslLight4: var(--colorHue), 40%, 70%;
  --colorHslLight5: var(--colorHue), 20%, 70%;
  --colorHslLighter1: var(--colorHue), 100%, 90%;
  --colorHslLighter2: var(--colorHue), 80%, 90%;
  --colorHslLighter3: var(--colorHue), 60%, 90%;
  --colorHslLighter4: var(--colorHue), 40%, 90%;
  --colorHslLighter5: var(--colorHue), 20%, 90%;
  --colorHslDark1: var(--colorHue), 100%, 30%;
  --colorHslDark2: var(--colorHue), 80%, 30%;
  --colorHslDark3: var(--colorHue), 60%, 30%;
  --colorHslDark4: var(--colorHue), 40%, 30%;
  --colorHslDark5: var(--colorHue), 20%, 30%;
  --colorHslDarker1: var(--colorHue), 100%, 10%;
  --colorHslDarker2: var(--colorHue), 80%, 10%;
  --colorHslDarker3: var(--colorHue), 60%, 10%;
  --colorHslDarker4: var(--colorHue), 40%, 10%;
  --colorHslDarker5: var(--colorHue), 20%, 10%;

  --separatorColor: hsla(var(--colorHslLight1), 0.2);
}

a{
  color: inherit;
  text-decoration: none;
}

.invisible{
  opacity: 0;
}

.d-none{
  display: none;
}

p{
  line-height: var(--lineHeight);

  &:first-child{
    margin-top: 0;
  }
  &:last-child{
    margin-bottom: 0;
  }
}

main{
  min-height: calc(100vh - var(--navHeight));
  margin-top: var(--navHeight);

  background: var(--color);
}

*{
  scroll-margin-top: var(--navHeight);
}


.slide-in{
  > *{
    opacity: 0;
  }


  >:nth-child(1){animation: slide-in 0.5s ease-out forwards 0.5s;}
  >:nth-child(2){animation: slide-in 0.5s ease-out forwards 0.6s;}
  >:nth-child(3){animation: slide-in 0.5s ease-out forwards 0.7s;}
  >:nth-child(4){animation: slide-in 0.5s ease-out forwards 0.8s;}
  >:nth-child(5){animation: slide-in 0.5s ease-out forwards 0.9s;}
  >:nth-child(6){animation: slide-in 0.5s ease-out forwards 1.1s;}
  >:nth-child(7){animation: slide-in 0.5s ease-out forwards 1.2s;}
  >:nth-child(8){animation: slide-in 0.5s ease-out forwards 1.3s;}
  @keyframes slide-in{
    0%{
      transform: translateX(100%);
      opacity: 0;
    }
    100%{
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.initialInvis,
.target{
  visibility: hidden;
}

span.target{
  display: inline-block;
}


::selection{
  color: var(--white);

  background-color: var(--blue3);

  transition: color var(--speed1) ease-in-out, background-color var(--speed1) ease-in-out;
}
/*width*/
::-webkit-scrollbar{
  width: 1rem;
}

/*Track*/
::-webkit-scrollbar-track{
  background: var(--blueDark4);
}

/*Handle*/
::-webkit-scrollbar-thumb{
  background: var(--blueLight4);
}

/*Handle on hover*/
::-webkit-scrollbar-thumb:hover{
  background: var(--blueLight2);
}


ul,
ol{
  line-height: var(--lineHeight);
}
