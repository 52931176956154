.markdown{
  h1{
    margin: var(--spacing7) 0 var(--spacing5) 0;

    font-size: var(--fontSize5);
    font-family: var(--secondaryFont);
    line-height: 1em;
    @media (max-width: 800px){
      font-size: var(--fontSize4);
    }
    @media (max-width: 600px){
      font-size: var(--fontSize3);
    }

    &:first-child{
      margin-top: 0;
    }
  }

  h2{
    margin: var(--spacing7) 0 var(--spacing4) 0;

    font-weight: 700;

    font-size: var(--fontSize4);
    font-family: var(--secondaryFont);
    line-height: 1em;
    @media (max-width: 600px){
      font-size: var(--fontSize3);
    }
    &:first-child{
      margin-top: 0;
    }
  }

  h3{
    margin: var(--spacing7) 0 var(--spacing3) 0;

    font-weight: 700;

    font-size: var(--fontSize3);
    font-family: var(--secondaryFont);
    line-height: 1em;
    @media (max-width: 800px){
      font-size: var(--fontSize2);
    }

    &:first-child{
      margin-top: 0;
    }
  }

  h4{
    margin: var(--spacing6) 0 var(--spacing2) 0;

    font-weight: 700;

    font-size: var(--fontSize2);
    font-family: var(--secondaryFont);
    line-height: 1em;

    &:first-child{
      margin-top: 0;
    }
  }


  figure{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--spacing5) 0;
    img{
      max-width: 100%;
    }
    figcaption{
      width: 100%;

      font-style: italic;

      text-align: center;
    }
  }

  img{
    display: block;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;

    border-radius: var(--curve);
  }

  blockquote{
    position: relative;

    margin: var(--spacing5) 0;
    padding: var(--spacing5);

    overflow: hidden;

    background-color: hsla(var(--blueHsl), 0.05);
    border-radius: var(--curve);
    >*:first-child{
      margin-top: 0;
    }
    >*:last-child{
      margin-bottom: 0;
    }
  }

  pre{
    padding: var(--spacing5);
    overflow: hidden;

    background-color: hsla(var(--blueHsl), 0.05);
    border-left: 3px solid var(--blue);
    border-radius: var(--curve);

    code{
      white-space: pre-wrap;
    }
  }

  table{
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    background-color: var(--gray9);
    border-radius: var(--curve);
    border-collapse: true;
    border-spacing: 0;

    thead{
      color: var(--white);

      background: var(--brown);
      background-color: var(--blue);
    }

    td,
    th{
      padding: var(--spacing4) var(--spacing5);
    }

    tr:nth-child(2n+1){
      background: hsla(var(--blueHsl), 0.01);
    }

    tr:nth-child(2n){
      background: hsla(var(--blueHsl), 0.2);
    }

    &:not(:last-child){
      margin-bottom: var(--spacing5);
    }
  }


  .tiktok-embed{
    max-width: min-content;
    margin: 0 auto;
    padding: 0;

    background: transparent;
  }
}
