h1,
.h1{
  display: flex;
  flex-direction: column;
  margin: 0 0 var(--spacing5) 0;

  font-weight: 700;
  font-size: var(--fontSize7);

  line-height: 1.3em;
  @media (max-width: 1100px){
    font-size: var(--fontSize6);
  }
  @media (max-width: 750px){
    font-size: var(--fontSize5);
  }
  @media (max-width: 550px){
    font-size: var(--fontSize4);
  }
  @media (max-width: 450px){
    font-size: var(--fontSize3);
  }
}

h2,
.h2{
  margin: 0 0 var(--spacing6) 0;

  font-weight: 700;

  font-size: var(--fontSize6);
  line-height: 1.3em;
  @media (max-width: 900px){
    font-size: var(--fontSize5);
  }
  @media (max-width: 768px){
    font-size: var(--fontSize4);
  }
  @media (max-width: 400px){
    font-size: var(--fontSize3);
  }

  &.underlined{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;

    text-align: center;

    &::after{
      width: 6.25rem;
      height: 0.75rem;
      margin-top: var(--spacing5);

      background-color: var(--white);
      border-radius: 0.75rem;

      content: "";
      @media (max-width: 900px){
        width: 5.25rem;
        height: 0.5rem;
        margin-top: var(--spacing4);
      }
      @media (max-width: 768px){
        width: 4.25rem;
        height: 0.4rem;
        margin-top: var(--spacing3);
      }
      @media (max-width: 400px){
        width: 3.25rem;
        height: 0.3rem;
        margin-top: var(--spacing2);
      }
    }
  }
}

h3,
.h3{
  display: flex;
  flex-direction: column;
  margin: 0 0 var(--spacing5) 0;

  font-weight: 700;

  font-size: var(--fontSize3);
  line-height: 1.3em;
  @media (max-width: 400px){
    font-size: var(--fontSize2);
  }
}

