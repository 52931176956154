@font-face{
  font-weight: 100;
  font-family: "Montserrat";
  font-style: normal;
  src: url("/fonts/JTUQjIg1_i6t8kCHKm45_QpRyS7m.woff2") format("woff2");
  font-display: block;
  unicode-range: u+0000-00ff, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
}
@font-face{
  font-weight: 400;
  font-family: "Montserrat";
  font-style: normal;
  src: url("/fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2") format("woff2");
  font-display: block;
  unicode-range: u+0000-00ff, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
}
@font-face{
  font-weight: 700;
  font-family: "Montserrat";
  font-style: normal;
  src: url("/fonts/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2") format("woff2");
  font-display: block;
  unicode-range: u+0000-00ff, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
}


/*latin*/
@font-face{
  font-weight: 300;
  font-family: "Open Sans";
  font-style: normal;
  font-stretch: 100%;
  src: url("/fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*latin*/
@font-face{
  font-weight: 400;
  font-family: "Open Sans";
  font-style: normal;
  font-stretch: 100%;
  src: url("/fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*latin*/
@font-face{
  font-weight: 700;
  font-family: "Open Sans";
  font-style: normal;
  font-stretch: 100%;
  src: url("/fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/*latin*/
@font-face{
  font-weight: 300;
  font-family: "Roboto";
  font-style: normal;
  src: url("/fonts/KFOlCnqEu92Fr1MmSU5fBBc4.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*latin*/
@font-face{
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  src: url("/fonts/KFOmCnqEu92Fr1Mu4mxK.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*latin*/
@font-face{
  font-weight: 700;
  font-family: "Roboto";
  font-style: normal;
  src: url("/fonts/KFOlCnqEu92Fr1MmWUlfBBc4.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
