/*variables*/
:root{
  //the --color variables are defined in _core.scss in #root
  //this lets them get overriden in each page

  --gray1: hsl(0, 0%, 0%);
  --gray2: hsl(0, 0%, 10%);
  --gray3: hsl(0, 0%, 20%);
  --gray4: hsl(0, 0%, 30%);
  --gray5: hsl(0, 0%, 40%);
  --gray6: hsl(0, 0%, 50%);
  --gray7: hsl(0, 0%, 60%);
  --gray8: hsl(0, 0%, 70%);
  --gray9: hsl(0, 0%, 90%);
  --gray10: hsl(0, 0%, 100%);


  --gray: #d1d1d1;

  --blueHue: 231;
  --blue: #090e2b;
  --blue1: hsl(var(--blueHue), 100%, 50%);
  --blue2: hsl(var(--blueHue), 80%, 50%);
  --blue3: hsl(var(--blueHue), 60%, 50%);
  --blue4: hsl(var(--blueHue), 40%, 50%);
  --blue5: hsl(var(--blueHue), 20%, 50%);
  --blueLight1: hsl(var(--blueHue), 100%, 70%);
  --blueLight2: hsl(var(--blueHue), 80%, 70%);
  --blueLight3: hsl(var(--blueHue), 60%, 70%);
  --blueLight4: hsl(var(--blueHue), 40%, 70%);
  --blueLight5: hsl(var(--blueHue), 20%, 70%);
  --blueLighter1: hsl(var(--blueHue), 100%, 90%);
  --blueLighter2: hsl(var(--blueHue), 80%, 90%);
  --blueLighter3: hsl(var(--blueHue), 60%, 90%);
  --blueLighter4: hsl(var(--blueHue), 40%, 90%);
  --blueLighter5: hsl(var(--blueHue), 20%, 90%);
  --blueDark1: hsl(var(--blueHue), 100%, 30%);
  --blueDark2: hsl(var(--blueHue), 80%, 30%);
  --blueDark3: hsl(var(--blueHue), 60%, 30%);
  --blueDark4: hsl(var(--blueHue), 40%, 30%);
  --blueDark5: hsl(var(--blueHue), 20%, 30%);
  --blueDarker1: hsl(var(--blueHue), 100%, 10%);
  --blueDarker2: hsl(var(--blueHue), 80%, 10%);
  --blueDarker3: hsl(var(--blueHue), 60%, 10%);
  --blueDarker4: hsl(var(--blueHue), 40%, 10%);
  --blueDarker5: hsl(var(--blueHue), 20%, 10%);

  --blueHsl: 231, 65%, 10%;
  --blueHsl1: var(--blueHue), 100%, 50%;
  --blueHsl2: var(--blueHue), 80%, 50%;
  --blueHsl3: var(--blueHue), 60%, 50%;
  --blueHsl4: var(--blueHue), 40%, 50%;
  --blueHsl5: var(--blueHue), 20%, 50%;
  --blueHslLight1: var(--blueHue), 100%, 70%;
  --blueHslLight2: var(--blueHue), 80%, 70%;
  --blueHslLight3: var(--blueHue), 60%, 70%;
  --blueHslLight4: var(--blueHue), 40%, 70%;
  --blueHslLight5: var(--blueHue), 20%, 70%;
  --blueHslLighter1: var(--blueHue), 100%, 90%;
  --blueHslLighter2: var(--blueHue), 80%, 90%;
  --blueHslLighter3: var(--blueHue), 60%, 90%;
  --blueHslLighter4: var(--blueHue), 40%, 90%;
  --blueHslLighter5: var(--blueHue), 20%, 90%;
  --blueHslDark1: var(--blueHue), 100%, 30%;
  --blueHslDark2: var(--blueHue), 80%, 30%;
  --blueHslDark3: var(--blueHue), 60%, 30%;
  --blueHslDark4: var(--blueHue), 40%, 30%;
  --blueHslDark5: var(--blueHue), 20%, 30%;
  --blueHslDarker1: var(--blueHue), 100%, 10%;
  --blueHslDarker2: var(--blueHue), 80%, 10%;
  --blueHslDarker3: var(--blueHue), 60%, 10%;
  --blueHslDarker4: var(--blueHue), 40%, 10%;
  --blueHslDarker5: var(--blueHue), 20%, 10%;


  --purpleHue: 234;
  --purple: #4752be;
  --purple1: hsl(var(--purpleHue), 100%, 50%);
  --purple2: hsl(var(--purpleHue), 80%, 50%);
  --purple3: hsl(var(--purpleHue), 60%, 50%);
  --purple4: hsl(var(--purpleHue), 40%, 50%);
  --purple5: hsl(var(--purpleHue), 20%, 50%);
  --purpleLight1: hsl(var(--purpleHue), 100%, 70%);
  --purpleLight2: hsl(var(--purpleHue), 80%, 70%);
  --purpleLight3: hsl(var(--purpleHue), 60%, 70%);
  --purpleLight4: hsl(var(--purpleHue), 40%, 70%);
  --purpleLight5: hsl(var(--purpleHue), 20%, 70%);
  --purpleLighter1: hsl(var(--purpleHue), 100%, 90%);
  --purpleLighter2: hsl(var(--purpleHue), 80%, 90%);
  --purpleLighter3: hsl(var(--purpleHue), 60%, 90%);
  --purpleLighter4: hsl(var(--purpleHue), 40%, 90%);
  --purpleLighter5: hsl(var(--purpleHue), 20%, 90%);
  --purpleDark1: hsl(var(--purpleHue), 100%, 30%);
  --purpleDark2: hsl(var(--purpleHue), 80%, 30%);
  --purpleDark3: hsl(var(--purpleHue), 60%, 30%);
  --purpleDark4: hsl(var(--purpleHue), 40%, 30%);
  --purpleDark5: hsl(var(--purpleHue), 20%, 30%);
  --purpleDarker1: hsl(var(--purpleHue), 100%, 10%);
  --purpleDarker2: hsl(var(--purpleHue), 80%, 10%);
  --purpleDarker3: hsl(var(--purpleHue), 60%, 10%);
  --purpleDarker4: hsl(var(--purpleHue), 40%, 10%);
  --purpleDarker5: hsl(var(--purpleHue), 20%, 10%);

  --purpleHsl: 234, 48%, 51%;
  --purpleHsl1: var(--purpleHue), 100%, 50%;
  --purpleHsl2: var(--purpleHue), 80%, 50%;
  --purpleHsl3: var(--purpleHue), 60%, 50%;
  --purpleHsl4: var(--purpleHue), 40%, 50%;
  --purpleHsl5: var(--purpleHue), 20%, 50%;
  --purpleHslLight1: var(--purpleHue), 100%, 70%;
  --purpleHslLight2: var(--purpleHue), 80%, 70%;
  --purpleHslLight3: var(--purpleHue), 60%, 70%;
  --purpleHslLight4: var(--purpleHue), 40%, 70%;
  --purpleHslLight5: var(--purpleHue), 20%, 70%;
  --purpleHslLighter1: var(--purpleHue), 100%, 90%;
  --purpleHslLighter2: var(--purpleHue), 80%, 90%;
  --purpleHslLighter3: var(--purpleHue), 60%, 90%;
  --purpleHslLighter4: var(--purpleHue), 40%, 90%;
  --purpleHslLighter5: var(--purpleHue), 20%, 90%;
  --purpleHslDark1: var(--purpleHue), 100%, 30%;
  --purpleHslDark2: var(--purpleHue), 80%, 30%;
  --purpleHslDark3: var(--purpleHue), 60%, 30%;
  --purpleHslDark4: var(--purpleHue), 40%, 30%;
  --purpleHslDark5: var(--purpleHue), 20%, 30%;
  --purpleHslDarker1: var(--purpleHue), 100%, 10%;
  --purpleHslDarker2: var(--purpleHue), 80%, 10%;
  --purpleHslDarker3: var(--purpleHue), 60%, 10%;
  --purpleHslDarker4: var(--purpleHue), 40%, 10%;
  --purpleHslDarker5: var(--purpleHue), 20%, 10%;


  --cyanHue: 201;
  --cyan: #42bbfc;
  --cyan1: hsl(var(--cyanHue), 100%, 50%);
  --cyan2: hsl(var(--cyanHue), 80%, 50%);
  --cyan3: hsl(var(--cyanHue), 60%, 50%);
  --cyan4: hsl(var(--cyanHue), 40%, 50%);
  --cyan5: hsl(var(--cyanHue), 20%, 50%);
  --cyanLight1: hsl(var(--cyanHue), 100%, 70%);
  --cyanLight2: hsl(var(--cyanHue), 80%, 70%);
  --cyanLight3: hsl(var(--cyanHue), 60%, 70%);
  --cyanLight4: hsl(var(--cyanHue), 40%, 70%);
  --cyanLight5: hsl(var(--cyanHue), 20%, 70%);
  --cyanLighter1: hsl(var(--cyanHue), 100%, 90%);
  --cyanLighter2: hsl(var(--cyanHue), 80%, 90%);
  --cyanLighter3: hsl(var(--cyanHue), 60%, 90%);
  --cyanLighter4: hsl(var(--cyanHue), 40%, 90%);
  --cyanLighter5: hsl(var(--cyanHue), 20%, 90%);
  --cyanDark1: hsl(var(--cyanHue), 100%, 30%);
  --cyanDark2: hsl(var(--cyanHue), 80%, 30%);
  --cyanDark3: hsl(var(--cyanHue), 60%, 30%);
  --cyanDark4: hsl(var(--cyanHue), 40%, 30%);
  --cyanDark5: hsl(var(--cyanHue), 20%, 30%);
  --cyanDarker1: hsl(var(--cyanHue), 100%, 10%);
  --cyanDarker2: hsl(var(--cyanHue), 80%, 10%);
  --cyanDarker3: hsl(var(--cyanHue), 60%, 10%);
  --cyanDarker4: hsl(var(--cyanHue), 40%, 10%);
  --cyanDarker5: hsl(var(--cyanHue), 20%, 10%);

  --cyanHsl: 201, 97%, 62%;
  --cyanHsl1: var(--cyanHue), 100%, 50%;
  --cyanHsl2: var(--cyanHue), 80%, 50%;
  --cyanHsl3: var(--cyanHue), 60%, 50%;
  --cyanHsl4: var(--cyanHue), 40%, 50%;
  --cyanHsl5: var(--cyanHue), 20%, 50%;
  --cyanHslLight1: var(--cyanHue), 100%, 70%;
  --cyanHslLight2: var(--cyanHue), 80%, 70%;
  --cyanHslLight3: var(--cyanHue), 60%, 70%;
  --cyanHslLight4: var(--cyanHue), 40%, 70%;
  --cyanHslLight5: var(--cyanHue), 20%, 70%;
  --cyanHslLighter1: var(--cyanHue), 100%, 90%;
  --cyanHslLighter2: var(--cyanHue), 80%, 90%;
  --cyanHslLighter3: var(--cyanHue), 60%, 90%;
  --cyanHslLighter4: var(--cyanHue), 40%, 90%;
  --cyanHslLighter5: var(--cyanHue), 20%, 90%;
  --cyanHslDark1: var(--cyanHue), 100%, 30%;
  --cyanHslDark2: var(--cyanHue), 80%, 30%;
  --cyanHslDark3: var(--cyanHue), 60%, 30%;
  --cyanHslDark4: var(--cyanHue), 40%, 30%;
  --cyanHslDark5: var(--cyanHue), 20%, 30%;
  --cyanHslDarker1: var(--cyanHue), 100%, 10%;
  --cyanHslDarker2: var(--cyanHue), 80%, 10%;
  --cyanHslDarker3: var(--cyanHue), 60%, 10%;
  --cyanHslDarker4: var(--cyanHue), 40%, 10%;
  --cyanHslDarker5: var(--cyanHue), 20%, 10%;


  --yellowHue: 48;
  --yellow: #ffce00;
  --yellow1: hsl(var(--yellowHue), 100%, 50%);
  --yellow2: hsl(var(--yellowHue), 80%, 50%);
  --yellow3: hsl(var(--yellowHue), 60%, 50%);
  --yellow4: hsl(var(--yellowHue), 40%, 50%);
  --yellow5: hsl(var(--yellowHue), 20%, 50%);
  --yellowLight1: hsl(var(--yellowHue), 100%, 70%);
  --yellowLight2: hsl(var(--yellowHue), 80%, 70%);
  --yellowLight3: hsl(var(--yellowHue), 60%, 70%);
  --yellowLight4: hsl(var(--yellowHue), 40%, 70%);
  --yellowLight5: hsl(var(--yellowHue), 20%, 70%);
  --yellowLighter1: hsl(var(--yellowHue), 100%, 90%);
  --yellowLighter2: hsl(var(--yellowHue), 80%, 90%);
  --yellowLighter3: hsl(var(--yellowHue), 60%, 90%);
  --yellowLighter4: hsl(var(--yellowHue), 40%, 90%);
  --yellowLighter5: hsl(var(--yellowHue), 20%, 90%);
  --yellowDark1: hsl(var(--yellowHue), 100%, 30%);
  --yellowDark2: hsl(var(--yellowHue), 80%, 30%);
  --yellowDark3: hsl(var(--yellowHue), 60%, 30%);
  --yellowDark4: hsl(var(--yellowHue), 40%, 30%);
  --yellowDark5: hsl(var(--yellowHue), 20%, 30%);
  --yellowDarker1: hsl(var(--yellowHue), 100%, 10%);
  --yellowDarker2: hsl(var(--yellowHue), 80%, 10%);
  --yellowDarker3: hsl(var(--yellowHue), 60%, 10%);
  --yellowDarker4: hsl(var(--yellowHue), 40%, 10%);
  --yellowDarker5: hsl(var(--yellowHue), 20%, 10%);

  --yellowHsl: 48, 100%, 50%;
  --yellowHsl1: var(--yellowHue), 100%, 50%;
  --yellowHsl2: var(--yellowHue), 80%, 50%;
  --yellowHsl3: var(--yellowHue), 60%, 50%;
  --yellowHsl4: var(--yellowHue), 40%, 50%;
  --yellowHsl5: var(--yellowHue), 20%, 50%;
  --yellowHslLight1: var(--yellowHue), 100%, 70%;
  --yellowHslLight2: var(--yellowHue), 80%, 70%;
  --yellowHslLight3: var(--yellowHue), 60%, 70%;
  --yellowHslLight4: var(--yellowHue), 40%, 70%;
  --yellowHslLight5: var(--yellowHue), 20%, 70%;
  --yellowHslLighter1: var(--yellowHue), 100%, 90%;
  --yellowHslLighter2: var(--yellowHue), 80%, 90%;
  --yellowHslLighter3: var(--yellowHue), 60%, 90%;
  --yellowHslLighter4: var(--yellowHue), 40%, 90%;
  --yellowHslLighter5: var(--yellowHue), 20%, 90%;
  --yellowHslDark1: var(--yellowHue), 100%, 30%;
  --yellowHslDark2: var(--yellowHue), 80%, 30%;
  --yellowHslDark3: var(--yellowHue), 60%, 30%;
  --yellowHslDark4: var(--yellowHue), 40%, 30%;
  --yellowHslDark5: var(--yellowHue), 20%, 30%;
  --yellowHslDarker1: var(--yellowHue), 100%, 10%;
  --yellowHslDarker2: var(--yellowHue), 80%, 10%;
  --yellowHslDarker3: var(--yellowHue), 60%, 10%;
  --yellowHslDarker4: var(--yellowHue), 40%, 10%;
  --yellowHslDarker5: var(--yellowHue), 20%, 10%;


  --pinkHue: 336;
  --pink: #e85d95;
  --pink1: hsl(var(--pinkHue), 100%, 50%);
  --pink2: hsl(var(--pinkHue), 80%, 50%);
  --pink3: hsl(var(--pinkHue), 60%, 50%);
  --pink4: hsl(var(--pinkHue), 40%, 50%);
  --pink5: hsl(var(--pinkHue), 20%, 50%);
  --pinkLight1: hsl(var(--pinkHue), 100%, 70%);
  --pinkLight2: hsl(var(--pinkHue), 80%, 70%);
  --pinkLight3: hsl(var(--pinkHue), 60%, 70%);
  --pinkLight4: hsl(var(--pinkHue), 40%, 70%);
  --pinkLight5: hsl(var(--pinkHue), 20%, 70%);
  --pinkLighter1: hsl(var(--pinkHue), 100%, 90%);
  --pinkLighter2: hsl(var(--pinkHue), 80%, 90%);
  --pinkLighter3: hsl(var(--pinkHue), 60%, 90%);
  --pinkLighter4: hsl(var(--pinkHue), 40%, 90%);
  --pinkLighter5: hsl(var(--pinkHue), 20%, 90%);
  --pinkDark1: hsl(var(--pinkHue), 100%, 30%);
  --pinkDark2: hsl(var(--pinkHue), 80%, 30%);
  --pinkDark3: hsl(var(--pinkHue), 60%, 30%);
  --pinkDark4: hsl(var(--pinkHue), 40%, 30%);
  --pinkDark5: hsl(var(--pinkHue), 20%, 30%);
  --pinkDarker1: hsl(var(--pinkHue), 100%, 10%);
  --pinkDarker2: hsl(var(--pinkHue), 80%, 10%);
  --pinkDarker3: hsl(var(--pinkHue), 60%, 10%);
  --pinkDarker4: hsl(var(--pinkHue), 40%, 10%);
  --pinkDarker5: hsl(var(--pinkHue), 20%, 10%);

  --pinkHsl: 336, 75%, 64%;
  --pinkHsl1: var(--pinkHue), 100%, 50%;
  --pinkHsl2: var(--pinkHue), 80%, 50%;
  --pinkHsl3: var(--pinkHue), 60%, 50%;
  --pinkHsl4: var(--pinkHue), 40%, 50%;
  --pinkHsl5: var(--pinkHue), 20%, 50%;
  --pinkHslLight1: var(--pinkHue), 100%, 70%;
  --pinkHslLight2: var(--pinkHue), 80%, 70%;
  --pinkHslLight3: var(--pinkHue), 60%, 70%;
  --pinkHslLight4: var(--pinkHue), 40%, 70%;
  --pinkHslLight5: var(--pinkHue), 20%, 70%;
  --pinkHslLighter1: var(--pinkHue), 100%, 90%;
  --pinkHslLighter2: var(--pinkHue), 80%, 90%;
  --pinkHslLighter3: var(--pinkHue), 60%, 90%;
  --pinkHslLighter4: var(--pinkHue), 40%, 90%;
  --pinkHslLighter5: var(--pinkHue), 20%, 90%;
  --pinkHslDark1: var(--pinkHue), 100%, 30%;
  --pinkHslDark2: var(--pinkHue), 80%, 30%;
  --pinkHslDark3: var(--pinkHue), 60%, 30%;
  --pinkHslDark4: var(--pinkHue), 40%, 30%;
  --pinkHslDark5: var(--pinkHue), 20%, 30%;
  --pinkHslDarker1: var(--pinkHue), 100%, 10%;
  --pinkHslDarker2: var(--pinkHue), 80%, 10%;
  --pinkHslDarker3: var(--pinkHue), 60%, 10%;
  --pinkHslDarker4: var(--pinkHue), 40%, 10%;
  --pinkHslDarker5: var(--pinkHue), 20%, 10%;


  --redHue: 1;
  --red: #ff322f;
  --red1: hsl(var(--redHue), 100%, 50%);
  --red2: hsl(var(--redHue), 80%, 50%);
  --red3: hsl(var(--redHue), 60%, 50%);
  --red4: hsl(var(--redHue), 40%, 50%);
  --red5: hsl(var(--redHue), 20%, 50%);
  --redLight1: hsl(var(--redHue), 100%, 70%);
  --redLight2: hsl(var(--redHue), 80%, 70%);
  --redLight3: hsl(var(--redHue), 60%, 70%);
  --redLight4: hsl(var(--redHue), 40%, 70%);
  --redLight5: hsl(var(--redHue), 20%, 70%);
  --redLighter1: hsl(var(--redHue), 100%, 90%);
  --redLighter2: hsl(var(--redHue), 80%, 90%);
  --redLighter3: hsl(var(--redHue), 60%, 90%);
  --redLighter4: hsl(var(--redHue), 40%, 90%);
  --redLighter5: hsl(var(--redHue), 20%, 90%);
  --redDark1: hsl(var(--redHue), 100%, 30%);
  --redDark2: hsl(var(--redHue), 80%, 30%);
  --redDark3: hsl(var(--redHue), 60%, 30%);
  --redDark4: hsl(var(--redHue), 40%, 30%);
  --redDark5: hsl(var(--redHue), 20%, 30%);
  --redDarker1: hsl(var(--redHue), 100%, 10%);
  --redDarker2: hsl(var(--redHue), 80%, 10%);
  --redDarker3: hsl(var(--redHue), 60%, 10%);
  --redDarker4: hsl(var(--redHue), 40%, 10%);
  --redDarker5: hsl(var(--redHue), 20%, 10%);

  --redHsl: 1, 100%, 59%;
  --redHsl1: var(--redHue), 100%, 50%;
  --redHsl2: var(--redHue), 80%, 50%;
  --redHsl3: var(--redHue), 60%, 50%;
  --redHsl4: var(--redHue), 40%, 50%;
  --redHsl5: var(--redHue), 20%, 50%;
  --redHslLight1: var(--redHue), 100%, 70%;
  --redHslLight2: var(--redHue), 80%, 70%;
  --redHslLight3: var(--redHue), 60%, 70%;
  --redHslLight4: var(--redHue), 40%, 70%;
  --redHslLight5: var(--redHue), 20%, 70%;
  --redHslLighter1: var(--redHue), 100%, 90%;
  --redHslLighter2: var(--redHue), 80%, 90%;
  --redHslLighter3: var(--redHue), 60%, 90%;
  --redHslLighter4: var(--redHue), 40%, 90%;
  --redHslLighter5: var(--redHue), 20%, 90%;
  --redHslDark1: var(--redHue), 100%, 30%;
  --redHslDark2: var(--redHue), 80%, 30%;
  --redHslDark3: var(--redHue), 60%, 30%;
  --redHslDark4: var(--redHue), 40%, 30%;
  --redHslDark5: var(--redHue), 20%, 30%;
  --redHslDarker1: var(--redHue), 100%, 10%;
  --redHslDarker2: var(--redHue), 80%, 10%;
  --redHslDarker3: var(--redHue), 60%, 10%;
  --redHslDarker4: var(--redHue), 40%, 10%;
  --redHslDarker5: var(--redHue), 20%, 10%;


  --greenHue: 150;
  --green: #3cb678;
  --green1: hsl(var(--greenHue), 100%, 50%);
  --green2: hsl(var(--greenHue), 80%, 50%);
  --green3: hsl(var(--greenHue), 60%, 50%);
  --green4: hsl(var(--greenHue), 40%, 50%);
  --green5: hsl(var(--greenHue), 20%, 50%);
  --greenLight1: hsl(var(--greenHue), 100%, 70%);
  --greenLight2: hsl(var(--greenHue), 80%, 70%);
  --greenLight3: hsl(var(--greenHue), 60%, 70%);
  --greenLight4: hsl(var(--greenHue), 40%, 70%);
  --greenLight5: hsl(var(--greenHue), 20%, 70%);
  --greenLighter1: hsl(var(--greenHue), 100%, 90%);
  --greenLighter2: hsl(var(--greenHue), 80%, 90%);
  --greenLighter3: hsl(var(--greenHue), 60%, 90%);
  --greenLighter4: hsl(var(--greenHue), 40%, 90%);
  --greenLighter5: hsl(var(--greenHue), 20%, 90%);
  --greenDark1: hsl(var(--greenHue), 100%, 30%);
  --greenDark2: hsl(var(--greenHue), 80%, 30%);
  --greenDark3: hsl(var(--greenHue), 60%, 30%);
  --greenDark4: hsl(var(--greenHue), 40%, 30%);
  --greenDark5: hsl(var(--greenHue), 20%, 30%);
  --greenDarker1: hsl(var(--greenHue), 100%, 10%);
  --greenDarker2: hsl(var(--greenHue), 80%, 10%);
  --greenDarker3: hsl(var(--greenHue), 60%, 10%);
  --greenDarker4: hsl(var(--greenHue), 40%, 10%);
  --greenDarker5: hsl(var(--greenHue), 20%, 10%);

  --greenHsl: 150, 50%, 47%;
  --greenHsl1: var(--greenHue), 100%, 50%;
  --greenHsl2: var(--greenHue), 80%, 50%;
  --greenHsl3: var(--greenHue), 60%, 50%;
  --greenHsl4: var(--greenHue), 40%, 50%;
  --greenHsl5: var(--greenHue), 20%, 50%;
  --greenHslLight1: var(--greenHue), 100%, 70%;
  --greenHslLight2: var(--greenHue), 80%, 70%;
  --greenHslLight3: var(--greenHue), 60%, 70%;
  --greenHslLight4: var(--greenHue), 40%, 70%;
  --greenHslLight5: var(--greenHue), 20%, 70%;
  --greenHslLighter1: var(--greenHue), 100%, 90%;
  --greenHslLighter2: var(--greenHue), 80%, 90%;
  --greenHslLighter3: var(--greenHue), 60%, 90%;
  --greenHslLighter4: var(--greenHue), 40%, 90%;
  --greenHslLighter5: var(--greenHue), 20%, 90%;
  --greenHslDark1: var(--greenHue), 100%, 30%;
  --greenHslDark2: var(--greenHue), 80%, 30%;
  --greenHslDark3: var(--greenHue), 60%, 30%;
  --greenHslDark4: var(--greenHue), 40%, 30%;
  --greenHslDark5: var(--greenHue), 20%, 30%;
  --greenHslDarker1: var(--greenHue), 100%, 10%;
  --greenHslDarker2: var(--greenHue), 80%, 10%;
  --greenHslDarker3: var(--greenHue), 60%, 10%;
  --greenHslDarker4: var(--greenHue), 40%, 10%;
  --greenHslDarker5: var(--greenHue), 20%, 10%;


  --white: #ffffff;
  --offWhite: #eeeeee;
  --black: #000000;

  --elevation0: none;
  --elevation1: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  --elevation2: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  --elevation3: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  --elevation4: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --elevation5: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  --elevation6: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  --elevation7: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.2);
  --elevation8: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
  --elevation9: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

  --spacing1: 0.146rem;
  --spacing2: 0.236rem;
  --spacing3: 0.382rem;
  --spacing4: 0.618rem;
  --spacing5: 1rem;
  --spacing6: 1.618rem;
  --spacing7: 2.618rem;
  --spacing8: 4.235rem;
  --spacing9: 6.854rem;
  --spacing10: 11.089rem;
  --spacing11: 17.942rem;
  --spacing12: 29.03rem;

  --fontSize0: 0.625rem;
  --fontSize1: 0.8125rem;
  --fontSize2: 1rem;
  --fontSize3: 1.25rem;
  --fontSize4: 1.625rem;
  --fontSize5: 2.0625rem;
  --fontSize6: 2.625rem;
  --fontSize7: 4.226rem;
  --fontSize8: 6.804rem;
  --fontSize9: 10.95rem;

  --sectionInnerMaxWidth: 1300px;
  --navHeight: 5rem;

  --lineHeight: 1.66em;
  --headerLineHeight: 1.33em;

  --curve: 3px;

  //--primaryFont: "Open Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  //--primaryFont: "Roboto", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --primaryFont: "Montserrat", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  --secondaryFont: var(--primaryFont);


  //--secondaryFont: "Montserrat", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  --thickBorderWidth: 4px;

  --speed1: 100ms;
  --speed2: 200ms;
  --speed3: 300ms;
  --speed4: 400ms;
  --speed5: 500ms;


  --dullImage: sepia(25%) brightness(90%) grayscale(10%);
  --normalImage: sepia(0%) brightness(100%) grayscale(0%);
}
