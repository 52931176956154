a:not(.custom){
  text-decoration: underline;

  background-color: hsla(var(--colorHslDarker1), 0);

  transition: background-color var(--speed1) ease-in-out, color var(--speed1) ease-in-out;

  &:hover{
    color: var(--white);

    background-color: hsla(var(--colorHslDarker1), 1);
  }
}
