figure{
  display: flex;
  flex-direction: column;
  align-items: center;

  img{
    overflow: hidden;

    border-radius: var(--curve);
  }

  figcaption{
    margin-top: var(--spacing3);

    font-size: var(--fontSize1);
    font-style: italic;
  }
}
